import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/rdizon/Documents/medgrocer/projects/wellness/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import SEO from "layout/SEO";
import Collapsible from "elements/Collapsible";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Home" mdxType="SEO" />
      <br />
      <h2>{`Frequently Asked Questions`}</h2>
      <hr />
      <h4>{`TaskUs Mental Health Program`}</h4>
      <Collapsible title="Who will see me after January 16, 2023?" mdxType="Collapsible">
        <p>{`Should you need further help, the TaskUs Wellness & Resiliency Coaches are there to facilitate a smooth transition of service to the new provider and/or the Employee Assistance Program (EAP) provider. Please reach out to your TaskUs Wellness & Resiliency Coach or send an email to `}<a parentName="p" {...{
            "href": "mailto:ph_wellnessresiliency@taskus.com"
          }}>{`ph_wellnessresiliency@taskus.com`}</a>{`. `}</p>
      </Collapsible>
      <Collapsible title="How do I reach the new provider?" mdxType="Collapsible">
        <p>{`For more information on the TaskUs provider, please reach out to your Wellness & Resiliency Coach or send an email to `}<a parentName="p" {...{
            "href": "mailto:ph_wellnessresiliency@taskus.com"
          }}>{`ph_wellnessresiliency@taskus.com`}</a>{`. `}</p>
      </Collapsible>
      <Collapsible title="I already tried but can’t seem to access or reach the EAP provider. What should I do?" mdxType="Collapsible">
        <p>{`If you are encountering issues with access to the Employee Assistance Program (EAP) provider, you may reach out to the TaskUs Wellness & Resiliency Coach in your site. They will be more than happy to help you with the resources you need. You may also send an email to `}<a parentName="p" {...{
            "href": "mailto:ph_wellnessresiliency@taskus.com"
          }}>{`ph_wellnessresiliency@taskus.com`}</a>{`. `}</p>
      </Collapsible>
      <Collapsible title="Who can I contact if I have questions or need more information?" mdxType="Collapsible">
        <p>{`For any questions regarding the transition, you may reach out to the TaskUs Wellness & Resiliency Coach or the Human Resources Team on your site. You may also send your queries via email to `}<a parentName="p" {...{
            "href": "mailto:ph_wellnessresiliency@taskus.com"
          }}>{`ph_wellnessresiliency@taskus.com`}</a>{`.`}</p>
      </Collapsible>
      <Collapsible title="What if I’m in an emergency mental health situation? Who can I reach out to?" mdxType="Collapsible">
        <p>{`TaskUs services for mental health crises are still available, and will continue to do so after the new vendor has begun their services.  There are multiple avenues for you to get help.`}</p>
        <p>{`You may reach out to your site’s you may reach out to the TaskUs Wellness & Resiliency Coach or directly contact the Employee Assistance Program (EAP) Provider through this link.`}</p>
      </Collapsible>
      <h4>{`MedGrocer Mental Health Program`}</h4>
      <Collapsible title="I want to keep seeing my assigned mental health professional. Can I continue with MedGrocer and pay the fees directly?" mdxType="Collapsible">
        <p>{`The MedGrocer Mental Health Program is currently only available for our partner companies and HMO. You may however reach out to our mental health professionals for information regarding their private practice and clinic hours outside of MedGrocer.`}</p>
      </Collapsible>
      <h4>{`Data and Personal Information`}</h4>
      <Collapsible title="Am I allowed to share post-consultation abstracts and other documents with the new provider?" mdxType="Collapsible">
        <p>{`Yes, you have ownership of your mental health information such as abstracts and post-consult documents. We highly encourage you to share abstracts and other information that may help your new provider to support you better. The post-consult documents are sent to you after attending your consultations with MedGrocer. Should you need assistance, please email `}<a parentName="p" {...{
            "href": "mailto:mentalhealth@medgrocer.com"
          }}>{`mentalhealth@medgrocer.com`}</a>{`.`}</p>
      </Collapsible>
      <Collapsible title="Who can I contact regarding my personal data?" mdxType="Collapsible">
        <p>{`Our Data Privacy Officer provides support regarding data privacy-related questions, comments, concerns, or complaints. You may contact MedGrocer’s Data Privacy Officer through the following:`}</p>
        <ul>
          <li parentName="ul">{`Mail: MG Health Solutions, Inc. (MedGrocer)`}</li>
          <li parentName="ul">{`24F Centuria Medical Makati, Kalayaan cor. Salamanca St., Makati City`}</li>
          <li parentName="ul">{`Call: 02 8332 3768`}</li>
          <li parentName="ul">{`Email: `}<a parentName="li" {...{
              "href": "dpo@medgrocer.com"
            }}>{`dpo@medgrocer.com`}</a></li>
        </ul>
        <p>{`MedGrocer commits to resolve complaints about our collection or use of your personal information. Individuals with inquiries or complaints regarding our Privacy Statement should first contact MedGrocer. If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our DPO (refer to the contact details above). MedGrocer’s Data Privacy Officer will always use reasonable efforts to address and settle any requests or complaints brought to its attention.`}</p>
      </Collapsible>
      <h4>{`Contact`}</h4>
      <p>{`For other concerns, feel free to reach us at: `}<a href="mailto:wellness@medgrocer.com" target="_blank" rel="noopener noreferrer" className="has-text-weight-bold has-text-primary">{`wellness@medgrocer.com`}</a></p>
    </Container>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      