import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/rdizon/Documents/medgrocer/projects/wellness/src/components/Layout/Layout.js";
import SEO from "layout/SEO";
import HomeBanner from "components/StaticPages/Home/HomeBanner"; // import HomeSavings from "components/StaticPages/Home/HomeSavings"

import HomeHowProgramWorks from "components/StaticPages/Home/HomeHowProgramWorks";
import CoachingVsCounseling from "components/StaticPages/Home/CoachingVsCounseling"; // import HomeGetStartedBanner from "components/StaticPages/Home/HomeGetStartedBanner"

import HelpCenter from "pages/help-center.mdx";
import Footer from "components/Layout/Footer";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home" mdxType="SEO" />
    <HomeBanner mdxType="HomeBanner" />
    <HelpCenter mdxType="HelpCenter" />
    <Footer mdxType="Footer" />
    {
      /* <HomeSavings /> */
    }
    {
      /* <CoachingVsCounseling /> */
    }
    {
      /* <HomeHowProgramWorks /> */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      